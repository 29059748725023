import React from "react";
import { Row, Col } from "react-bootstrap";
import applestore from "../assets/images/applestore.png";
import playstore from "../assets/images/playstore.png";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";

export default function Links({ heading, info, close }) {
  return (
    <div className="Links">
      <Row>
        <Col xs={6}>
          <Fade direction="bottom" duration={500}>
            <h5>Legal</h5>
          </Fade>
          <ul>
            <Fade
              direction="bottom"
              duration={500}
              cascade
              damping={0.2}
              delay={500}
            >
              <li>
                <Link to="terms-of-service/patients" onClick={close}>
                  {" "}
                  Patient Terms
                </Link>
              </li>
              <li>
                <Link to="terms-of-service/experts" onClick={close}>
                  Expert Terms
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={close}>
                  Privacy Policy{" "}
                </Link>
              </li>
            </Fade>
          </ul>
        </Col>
        <Col xs={6}>
          <Fade direction="bottom" duration={500}>
            <h5>Social</h5>
          </Fade>
          <div className="flex-container">
            <ul>
              <Fade
                direction="bottom"
                duration={500}
                cascade
                damping={0.2}
                delay={500}
              >
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/fertilityanswers_/"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/medanswers.fertility"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/Medanswers_IVF">
                    Twitter
                  </a>
                </li>
              </Fade>
            </ul>
            <ul className="second-ul">
              <Fade
                direction="bottom"
                duration={500}
                cascade
                damping={0.2}
                delay={500}
              >
                <li>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCYH7kzYmFiMAWJOar2ORj9g"
                  >
                    YouTube
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/medanswers/"
                  >
                    Linkedin
                  </a>
                </li>
                <li>
                  <a target="_blank" href="">
                    Pinterest
                  </a>
                </li>
              </Fade>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
}
