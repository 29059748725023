//all imports
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import applestore from "../assets/images/applestore.png";
import playstore from "../assets/images/playstore.png";
import Features from "./features.js";
import FotterLink from "./links.js";
import { Link } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../../../state/app.js";
import { Fade } from "react-awesome-reveal";

export const SocialBox = () => (
  <div className="socialbox">
    <a
      target="_blank"
      href="https://apps.apple.com/us/app/fertilityanswers/id1418650176"
    >
      <img src={applestore} alt="Apple Store Logo - Apple Store App link" />
    </a>
    <a
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.fertilityanswers"
    >
      <img src={playstore} alt="Play Store Logo - Play Store App link" />
    </a>
  </div>
);

//main functions
export default function Menu({ toggle }) {
  const menustatus = useSelector((state) => state.app.openHambergermenu);

  const dispatch = useDispatch();

  //toggle hamberger menu
  var togglemenu = () => {
    document.body.classList.toggle("menuexpand");

    if (menustatus === false) {
      dispatch(toggleMenu(true));
    } else {
      dispatch(toggleMenu(false));
    }
  };
  //jsx
  return (
    <div className="content">
      <Row>
        <Col xs={12} sm={5} md={6}>
          <ul className="menuitems">
            <Fade direction="bottom" duration={500} cascade damping={0.2}>
              <li>
                <Link to="/" onClick={togglemenu}>
                  Home{" "}
                </Link>
              </li>
              <li>
                <Link to="/questions" onClick={togglemenu}>
                  Questions{" "}
                </Link>
              </li>
              <li>
                <Link to="partners" onClick={togglemenu}>
                  Find Help
                </Link>
              </li>
              <li>
                <Link to="/how-it-works" onClick={togglemenu}>
                  How it works
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={togglemenu}>
                  About{" "}
                </Link>
              </li>
              {/*  <li>
                <Link className="login-show" to="/login" onClick={togglemenu}>
                  Log in
                </Link>
            </li> */}
            </Fade>
          </ul>
          {/* <div className="d-none d-md-block">
            <SocialBox />
          </div> */}
        </Col>
        <Col xs={12} sm={7} md={5}>
          <Fade direction="bottom" duration={500} cascade damping={0.2}>
            <div>
              <Features
                heading={"Fertility Edge Magazine"}
                info={"Check out articles written by our experts "}
                page={"fertile-edge-magazine"}
                toggleclose={togglemenu}
              />
            </div>

            <div>
              <Features
                heading={"MedAnswers"}
                info={
                  "Learn more about our parent company MedAnswers and other ways they are helping"
                }
                page={"MedAnswers"}
                toggleclose={togglemenu}
              />
            </div>

            <div>
              <Features
                heading={"FAQs"}
                info={
                  "This section covers how to use the digital platform. Find fertility related answers in the top search bar"
                }
                page={"faqs"}
                toggleclose={togglemenu}
              />
            </div>
            {
              <div className="d-md-none">
                <SocialBox />
              </div>
            }
          </Fade>
          <FotterLink close={togglemenu} />
        </Col>
      </Row>
    </div>
  );
}
